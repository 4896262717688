import React, { useEffect } from "react";
import MapComponent from "../components/Map";
import SocialMediaIcons from "../components/SocialMedia";
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-page parent">
      <div className="contact-header div1">
        <h1>Contact Me</h1>
        <SocialMediaIcons />
      </div>
      <div className="contact-form-container div2">
        <ContactForm />
      </div>
      <div className="contact-map-container div3">
        <MapComponent />
      </div>
    </div>
  );
};

export default ContactPage;
