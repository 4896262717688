import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
    }
  }, [executeRecaptcha]);

  const validate = (formData) => {
    let tempErrors = {};
    if (!formData.get("firstName"))
      tempErrors.firstName = "First Name is required";
    if (!formData.get("lastName"))
      tempErrors.lastName = "Last Name is required";
    if (!formData.get("email")) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.get("email")))
      tempErrors.email = "Email is invalid";
    if (!formData.get("message")) tempErrors.message = "Message is required";
    if (!formData.get("contactMethod"))
      tempErrors.contactMethod = "Preferred contact method is required";
    if (formData.get("phone") && !formData.get("voicemail"))
      tempErrors.voicemail =
        "Voicemail option is required if phone number is provided";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    if (validate(formData)) {
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("submit");
      setRecaptchaToken(token);

      try {
        const verifyResponse = await axios.post(
          "/.netlify/functions/verifyRecaptcha",
          { token }
        );

        if (verifyResponse.data.success) {
          const serviceId = "service_vw7ih5n"; // ✅ Updated to SMTP service
          const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
          const userId = process.env.REACT_APP_EMAILJS_USER_ID;

          await emailjs.send(
            serviceId,
            templateId,
            {
              to_email: "janellewilliamscounselling@gmail.com", // ✅ Janelle receives the email
              from_name:
                formData.get("firstName") + " " + formData.get("lastName"),
              from_email: formData.get("email"), // ✅ Client's email (used for reply)
              reply_to: formData.get("email"), // ✅ Ensures Janelle can reply to the client
              phone: formData.get("phone"),
              voicemail: formData.get("voicemail"),
              contact_method: formData.get("contactMethod"),
              message: formData.get("message"),
            },
            userId
          );

          form.reset();
          setErrors({});
          setStatus("SUCCESS");
        } else {
          setStatus("FAILED");
        }
      } catch (err) {
        console.error("Error sending email:", err);
        setStatus("FAILED");
      }
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeTHg0qAAAAAKp6T1NGw5AdoSnXq4ImKcTApdui">
      <div className="contact-form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" />
            {errors.firstName && (
              <div className="error">{errors.firstName}</div>
            )}
          </div>
          <div className="form-control">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" />
            {errors.lastName && <div className="error">{errors.lastName}</div>}
          </div>
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <div className="form-control">
            <label htmlFor="phone">Phone #</label>
            <input type="text" id="phone" name="phone" />
          </div>
          <div className="form-control">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message"></textarea>
            {errors.message && <div className="error">{errors.message}</div>}
          </div>
          <div className="form-control">
            <label>Can voicemail be left?</label>
            <div className="radio-group">
              <input
                type="radio"
                id="voicemailYes"
                name="voicemail"
                value="yes"
              />
              <label htmlFor="voicemailYes">Yes</label>
              <input
                type="radio"
                id="voicemailNo"
                name="voicemail"
                value="no"
              />
              <label htmlFor="voicemailNo">No</label>
            </div>
            {errors.voicemail && (
              <div className="error">{errors.voicemail}</div>
            )}
          </div>
          <div className="form-control">
            <label>Preferred contact method</label>
            <div className="radio-group">
              <input
                type="radio"
                id="contactMethodEmail"
                name="contactMethod"
                value="email"
              />
              <label htmlFor="contactMethodEmail">Email</label>
              <input
                type="radio"
                id="contactMethodPhone"
                name="contactMethod"
                value="phone"
              />
              <label htmlFor="contactMethodPhone">Phone</label>
              {errors.contactMethod && (
                <div className="error">{errors.contactMethod}</div>
              )}
            </div>
          </div>
          <button type="submit">Submit</button>
          {status === "SUCCESS" && (
            <p className="success-message">Thanks for your message!</p>
          )}
          {status === "FAILED" && (
            <p className="error-message">
              There was an error, please try again.
            </p>
          )}
        </form>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default ContactForm;
