import React, { useEffect } from "react";
import Button from "../components/Button";

const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="services-page">
      <div className="section section-services gray">
        <div className="services-div-one">
          <h1>Services</h1>
        </div>
      </div>
      <div className="divider-container">
        <hr className="section-divider" />
      </div>
      <div className="section section-services kaki">
        <div className="services-div-two">
          <h1>INDIVIDUAL COUNSELLING:</h1>
          <p>
            Currently offering individual counselling in-person in St. John's,
            and virtually across Newfoundland and Labrador. Utilizing a variety
            of approaches including Acceptance and Commitment Therapy,
            Emotion-Focused Therapy, Dialectical Behaviour Therapy, Cognitive
            Behavioural Therapy and Solution-Focused Therapy, individual
            counselling can be tailored to meet your specific therapeutic goals.
          </p>
        </div>
      </div>
      <div className="divider-container">
        <hr className="section-divider" />
      </div>
      <div className="section section-services gray">
        <div className="services-div-three">
          <h1>COUPLES COUNSELLING:</h1>
          <p>
            Currently offering couples counselling in-person in St. John's only.
            Janelle has completed training in the Gottman Method - an
            evidence-based approach to couples counselling that aims to build
            stronger relationships and foster positive coping and communication
            skills.
          </p>
        </div>
      </div>
      <div className="divider-container">
        <hr className="section-divider" />
      </div>
      <div className="section section-services kaki">
        <div className="services-div-four">
          <h1>WALK AND TALK THERAPY:</h1>
          <p>
            Not interested in traditional counselling? Janelle is currently
            offering Walk and Talk therapy in-person in St. John's only. Walk
            and Talk therapy is a form of outdoor therapy that integrates
            physical movement, grounding exercises and breath work into the
            counselling session.
          </p>
        </div>
      </div>
      <div className="divider-container">
        <hr className="section-divider" />
      </div>
      <div className="section section-services gray">
        <div className="services-div-five">
          <h1>AREAS OF SERVICE:</h1>
          <p>
            Janelle has a keen interest in working alongside clients
            experiencing anxiety, depression, (dis)ability, life transitions,
            grief and loss, infertility, perinatal mental health concerns, low
            self-esteem, emotional regulation challenges and/or loss of
            independence. Janelle has also completed additional training in many
            areas such as supporting caregivers of older adults, stages of
            adjustment to childhood (dis)ability and perinatal mental health.
          </p>
        </div>
      </div>
      <hr className="section-divider" />
      <div className="section section-services kaki">
        <div className="services-div-four">
          <h1>Questions?:</h1>
          <p>
            I am more than happy to answer questions via email or phone. Please
            reach out today to schedule a free 15-minute consultation.
          </p>
          <Button className="button" to="/contact">
            Book Now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
