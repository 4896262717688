import React, { useEffect } from "react";
import Janelle from "../assets/images/Janelle.JPG";
import Button from "../components/Button";
import OfficePhoto from "../assets/images/Office.jpeg";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <div className=" section-about gray">
        <div className="div1">
          <div className="about-title">
            <h1>Janelle Williams, MSW</h1>
            <h2>Registered Social Worker</h2>
          </div>
          <p className="about-text-intro">
            Janelle is a registered social worker with the Newfoundland and
            Labrador College of Social Workers. She holds a Bachelor of Social
            Work degree from Memorial University and a Master of Social Work
            degree from the University of Waterloo. Janelle draws from multiple
            therapeutic modalities including Acceptance and Commitment Therapy,
            Emotion-Focused Therapy, Dialectical Behaviour Therapy, Cognitive
            Behavioural Therapy, and Solution-Focused Therapy. She has also
            completed training in many areas such as supporting caregivers of
            older adults, perinatal mental health, Gottman Method couples
            counselling, and more.
          </p>
        </div>
        <div className="div2">
          <img
            src={Janelle}
            alt="Picture of Janelle"
            className="about-picture"
          />
        </div>
      </div>
      <div className="section-kaki">
        <div className="div4">
          <img
            src={OfficePhoto}
            alt="Office photo"
            className="about-picture-office"
          />
        </div>
        <div className="div3">
          <h1>Now Accepting New Clients</h1>
          <p>
            Janelle is currently accepting new clients for individual
            counselling (children, teenagers, adults, and seniors) and couples
            counselling. Janelle has a keen interest in working alongside
            clients experiencing anxiety, depression, (dis)ability, life
            transitions, grief and loss, infertility, perinatal mental health
            concerns, low self-esteem, emotional regulation challenges, and/or
            loss of independence. If you believe that you might be a good fit
            with Janelle, reach out to schedule your free 15-minute phone
            consultation. The consultation is an opportunity to get to know each
            other and mutually decide on therapeutic plans/goals. This is a
            judgment-free conversation, and there is no obligation to book a
            session following your consultation.
          </p>
          <p>
            Receipts will be provided for insurance reimbursement/tax purposes.
            Your session can be covered by most insurance policies under the
            category of Registered Social Worker. Please check with your
            insurance provider to ensure proper coverage/reimbursement.
          </p>
          <Button className="button" to="/contact">
            Contact
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
